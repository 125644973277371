// =========== Visual
.l-visual{
	background-color: rgba(0,0,0,0.3);
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;

	.l-container {
		padding: 0;
	}

	.intro-logo {
		background-image: url('../images/introduction-logo.png');
		background-size: contain;
		height: 120px;
		width: 120px;


		@include mq(medium) {
			float: left;
			left: 50%;
			transform: translateX(-50%);
			position: relative;
			top: auto;
		}

		position: absolute;
		top: 3%;
		right: 5%;
	}

	.visual__body{
		display: block;
		position: absolute;
		//float: left;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		text-align: center;
		margin: 0;
		padding: 0;
		overflow: auto;

		&.centered {
			position: absolute;
			top: 40%;
			transform: translateY(-50%);
			float: none;
		}

		h1 {
			margin: 10px 0;
			font-weight: 600;
			text-transform: uppercase;
		}

		.tech-tags {
			display: inline-block;

			.tag {
				font-weight: 600;
				color: white;
				float: left;
				background: $bluenormal;
				margin: 3px;
				padding: 2px 8px;
			}
		}


		.visual__gray-area {
			//padding-top: 40px;
			padding-bottom: 20px;
		}
		.icon{
			font-size: 66px;
			margin-bottom: 18px;
			display: none;
		}

		.title {
			border-top: dashed 3px white;
			border-bottom: dashed 3px white;
			//width: calc(100% - 80px);
			width: calc(100% - 60px);
			padding: 0 16px 6px 16px;
			margin: 10px auto;
			color: white;
			font-size: 38px;
			line-height: 38px;
			text-transform: uppercase;

			&.read-more-page {
				border: none;
			}

			@include mq(medium) {
				display: inline-block;
				max-width: 450px;
				width: auto;
			}
		}

		p {
			color: $white;
			margin: 0 auto 12px auto;
			font-size: 24px;
			font-weight: 300;
			line-height: 1.2;
			width: calc(100% - 30px);
			padding: 0 20px;

			span{
				display: none;
			}
		}
		.button{
			margin-top: 18px;
		}
	}
}

.home__page .l-visual{
	//background: url(../images/Background_Home.jpg) no-repeat;
	background-size: cover;
}

.single__page .l-visual{
	//background: url(../images/Background_Gebiedscooperatie.jpg) no-repeat;
	background-size: cover;
	height: 100vh;
	h1{
		background: none;
		margin-bottom: 6px;
	}
}

.logo__wrap{
	position: relative;
	float: left;
	width: 100%;
	padding: 10px;
	cursor: default;
	height: 80px;

	img{
		//width: 80%;
		height: auto;
		display: block;
		float: left;
		max-width: 350px;
		cursor: pointer;
	}

	.info-btn, .close-btn {
		cursor: pointer;
		display: none;
		position: absolute;
		right: 12px;
		width: 26px;
		height: 26px;
		top: 50%;
		transform: translateY(-50%);
	}

	.info-btn {
		background: url('../images/i_icon.svg') no-repeat;
	}

	.close-btn {
		background: url('../images/close_icon.svg') no-repeat;
		transition: transform 0.1s linear;

		&:hover {
			transform: translateY(-50%) rotate(90deg);
		}
	}

}