// ==========================================================================
// Mixins
// ==========================================================================

@mixin doubly($margin: 1em) {
	& + & {
	  margin-left: $margin;
    @content;
	}
}

@mixin rem($property, $values) {
  $px : ();
  $rem: ();

  @each $value in $values {

    @if $value == 0 or $value == auto {
      $px : append($px , $value);
      $rem: append($rem, $value);
    }

    @else {
      $unit: unit($value);
      $val: parseInt($value);

      @if $unit == "px" {
        $px : append($px,  $value);
        $rem: append($rem, ($val / $base__font-size + rem));
      }

      @if $unit == "rem" {
        $px : append($px,  ($val * $base__font-size + px));
        $rem: append($rem, $value);
      }
    }
  }

  @if $px == $rem {
    #{$property}: $px;
  } @else {
    #{$property}: $px;
    #{$property}: $rem;
  }
}

// Media queries

@mixin mq($break) {
	@if $break == "small" {
		@media (min-width: $brkpoint-sm) {
			@content;
		}
	}
	@else if $break == "medium" {
		@media (min-width: $brkpoint-md) {
			@content;
		}
	}
	@else if $break == "large" {
		@media (min-width: $brkpoint-lg) {
			@content;
		}
	}
	@else if $break == "extralarge" {
		@media (min-width: $brkpoint-xl) {
			@content;
		}
	}
	@else if $break == "hd" {
		@media (min-width: $brkpoint-xxl) {
			@content;
		}
	}
	@else {
		@error "Whoops! No value could be retrieved for `#{$break}`. "
	}
}