// ==========================================================================
// Utilities
// ==========================================================================
@import 'utilities/index';

// ==========================================================================
// Base
// ==========================================================================
@import 'base/index';

// ==========================================================================
// Modules
// ==========================================================================
@import 'modules/index';

// ==========================================================================
// Theme
// ==========================================================================
@import 'theme/index';

%background {
    background-repeat: no-repeat;
    background-position: center center !important;
    background-size: cover !important;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

.background-main {
    @extend %background;
    z-index: 2;
}

.background-second {
    @extend %background;
    z-index: 1;
    background-color: white;
}

.main-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    //padding-right: 60px;
    z-index: 3;
    .content-wrapper {
        display: inline-block;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: auto;
        -webkit-transform: translate3d(0,0,0);
    }
}