// =========== List
.card__list {
    list-style: none;
    margin: 0;
    padding: 0 0 60px 0;
    background-color: #CCC;

    @include mq(medium) {
        padding: 0 60px 0 0;
    }

    > li {
        float: left;
        width: 50%;
    }
}

@include mq(medium) {
    .card__list {
        > li {
            width: 50%;
        }
    }
}

@include mq(large) {
    .card__list {
        > li {
            width: 33.33%;
        }
    }
}

@include mq(hd) {
    .card__list {
        > li {
            width: 25%;
        }
    }
}