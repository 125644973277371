// =========== Base Styles

*,
*:after,
*:before {
    box-sizing: border-box;
}

html {
	color: #222;
	font: 400 $base__font-size - 2 + px $font-family--content;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: auto;
    -webkit-text-size-adjust: 100%;
    height: 100%;
    @include mq(medium) {
	   font: 400 $base__font-size + px $font-family--content;
	}
}

body {
	line-height: ($base__line-height/$base__font-size);
    height: 100%;
    min-width: 320px;
    position: relative;
}


h1, h2, legend, h3, h4, h5, h6 {
    font-family: $font-family--heading;
    font-weight: 700;
	color: white;
    line-height: ($base__line-height/$base__font-size);
    margin: 0 0 $spacing 0;
}
h1 {
	font-size: 2rem;
}
h2,
legend {
    font-size: 1.4rem;
}
h3 {
	font-size: 1.3rem;
}
h4 {
	font-size: 1.2rem;
}
h5 {
	font-size: 1.1rem;
}
h6 {
	font-size: 1rem;
}


p {
	margin: 0 0 1.625em;
}


a {
    color: $color-primary;
    cursor: pointer;
    text-decoration: none;
    transition: color 60ms ease-out;
    &:hover{
	    color: darken($color-primary, 10%);
    }
    &:active, &:focus{
	    color: darken($color-primary, 15%);
	    outline: none;
    }
}


em, i { font-style: italic; }

strong, b { font-weight: bold; }

code, pre { font-family: 'courier new', monospace; }

pre { overflow-x: auto; }

abbr { border-bottom: 1px dotted #EDEDED }

del { text-decoration: line-through; }

mark { background-color: lighten(yellow, 35%); }

hr {
    background-color: transparent;
    border: solid #EDEDED;
    border-width: 1px 0 0;
    clear: both;
    margin: 2.5rem $spacing;
}


ul, ol { margin: 0 0 $spacing 1.5rem; }

ul { list-style: disc; }

ol { list-style: decimal; }

dl {
    margin-bottom: $spacing;

    dt { font-weight: bold; }
    dd { margin-left: $spacing; }
}

