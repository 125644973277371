// =========== Header
.l-header{
	width: 100%;
	height: 60px;
	border-bottom: 1px solid #D5D5D5;
	padding: 0 12px;
	background: $white;
	z-index: 19;
	.logo__wrap{
		position: absolute;
		top: 23px;
		left: 30px;
		width: 100px;
		display: none;
		img{
			width: 100%;
			height: auto;
			display: block;
		}
	}
	h1{
		font-size: 1rem;
		text-transform: uppercase;
		margin: 0;
		padding: 18px 0;
		&:before{
			padding-right: 4px;
			font-size: 1.4em;
			vertical-align: -4px;
			display: none;
		}
	}
	#downloadBtn{
		position: absolute;
		top: 0;
		right: 0;
		height: 60px;
		border-left: 1px solid #D5D5D5;
		color: #707070;
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 700;
		padding: 20px 12px;
		&:hover{
			text-decoration: underline;
			&:after{
				text-decoration: none;
			}
		}
	}
	.download__wrap{
		position: absolute;
		z-index: 19;
		width: 250px;
		top: 59px;
		right: 12px;
		transition: all 0.2s ease-in-out;
		&.inactive{
		    opacity: 0;
		    transform: translate(0,-10%);
		    visibility: hidden;
		}
		&.active{
		    opacity: 1;
	    	transform: translate(0,0);
	    	visibility: visible;
		}
		.triangle-with-shadow {
			width: 23px;
		    height: 13px;
		    position: absolute;
		    right: 30px;
		    top: -11px;
		    z-index: 40;
		    overflow: hidden;
			&::after {
				content: "";
				position: absolute;
				width: 16px;
			    height: 16px;
			    background: $white;
			    transform: rotate(45deg);
			    top: 5px;
			    right: 3px;
				box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
			}
		}
	}
}
@include mq(small) {
	.l-header{
		h1:before{
			display: inline-block;
		}
	}
}

@include mq(medium) {
	.l-header{
		#downloadBtn{
			right: 60px;
			padding: 20px 18px;
		}
	}
}

@include mq(extralarge) {
	.l-header{
		.logo__wrap{
			display: block;
		}
		.download__wrap{
			right: 80px;
		}
	}
}