// ===========  Button
.button, button, input[type=submit]{
	//background: $color-primary;
	color: $white;
	padding: 12px 24px;
	display: inline-block;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
	background-color: $dark-primary;
	&:hover{
		//background: darken($color-primary, 10%);
		color: $white;
	}
	&:active, &:focus{
		//background: darken($color-primary, 20%);
		color: $white;
	}
	&:visited{
		color: $white;
	}
}

button, input[type=submit]{
	border: none;
	-webkit-appearance: none;
	text-transform: uppercase;
	font-weight: bold;
	padding: 12px 18px;
	line-height: 1;
	&:focus{
		outline: none;
	}
}

.button__border{
	background: transparent;
	border: 2px solid $color-primary;
	&:hover{
		//background: $white;
		//color: $color-primary;
		//border: 2px solid darken($color-primary, 10%);
	}
	&:active, &:focus{
		//background: $white;
		//color: $color-primary;
		//border: 2px solid darken($color-primary, 20%);
	}
}
.button__border--white{
	border-color: $white;
	&:hover{
		//border-color: $color-primary;
	}
	&:active, &:focus{
		//border-color: darken($color-primary, 20%);
	}
}

.button__readmore{
	background: transparent;
	position: relative;
	padding-top: 72px;
	&::before{
		position: absolute;
		top:0;
		left: 50%;
		margin-left: -30px;
		height: 50px;
		border: 2px solid $white;
		border-radius: 50%;
		font-size: 22px;
	}
	&:hover{
		background: transparent;
		color: $color-primary;
		&::before{
			border-color: $color-primary;
		}
	}
	&:active, &:focus{
		background: transparent;
		color: darken($color-primary, 20%);
		&::before{
			border-color: darken($color-primary, 20%);
		}
	}
}

.read-more {
	position: absolute;
	bottom: 100px;
	left: 50%;
	transform: translateX(-50%);
	opacitY: .5;
	border-radius: 50%;
	border: 3px solid #fff;
	background-color: #000;

	&.left {
		bottom: auto;
		top: 50%;
		left: 10px;
		transform: rotate(90deg) translate(-50%);

		@include mq(medium) {
			left: 50px;
		}
	}


	.read-more-icon {
		width: 50px;
		height: 50px;
		background: url('../images/down_arrow.png');
		background-repeat:no-repeat;
		background-position: center center;
		cursor: pointer;
	}

}