.search__sidebar {
    display: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $grey-94;
    z-index: 9901;

    &.open {
        display: block;
    }

    @include mq(medium) {
        position: fixed;
        display: block;
        width: 300px;
        min-height: 400px;
        height: auto;
        max-height: calc(100% - 40px);
        margin: 20px 0;
        right: -300px;
        transition: right 0.3s linear;
        border: solid 1px #c9c9c9;
        top: 66px;

        &.open {
            right: 0;
        }
    }

    .sidebar-header{
        position: relative;
        background-color: $grey-94;
        height: 50px;
        text-align: center;
        padding: 16px 0;
        width: 100%;

        &.search {
            height: auto;
        }

        input {
            width: calc(100% - 40px);
            margin: 20px 20px 5px 20px;
            height: 40px;
            padding: 0 0 0 10px;
            color: #222;
        }

        @include mq(medium) {
            height: 50px;
            padding: 16px 0;
        }

        .title {
            color: $dark-primary;
            font-size: 28px;
            line-height: 1.2;
            font-weight: 500;
        }

        .search-close-btn {
            cursor: pointer;
            border-radius: 50%;
            position: absolute;
            right: 10px;
            width: 40px;
            height: 40px;
            background: url('../images/close_icon_grey.svg') no-repeat center center;
            top: 5px;
            transition: transform 0.1s linear;

            &:hover {
                transform: rotate(90deg);
            }

            @include mq(medium) {
                width: 30px;
                height: 30px;
                top: 14px;
            }

            &:hover {
                transform: rotate(90deg);
            }
        }
    }


    .sidebar-content {
        position: relative;
        width: 100%;
        overflow: auto;
        height: calc(100vh - 184px);

        @include mq(medium) {
            min-height: 294px;
            height: auto;
            max-height: calc(100vh - 140px);
        }

        .pdf-row {
            position: relative;
            padding: 15px 34px 15px 15px;
            border-bottom: solid 1px #ccc;
            cursor: pointer;
            color: #222;

            .title {
                color: $Cinnabar;
                font-size: $base__font-size + 4 + px;
            }

            &:hover{
                background: $grey-96;
            }
        }
    }
}