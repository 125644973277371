form {
    font-size: 16px;
    max-width: 600px;

    label {
        display: block;
        margin-bottom: 8px;
    }

    input[type="checkbox"] {

    }

    input[type="email"] {

    }

    button {
        width: 80px;
        height: 40px;
        float: right;
        padding: 12px 13px !important;

        &:after {
            float: clear;
        }
    }

}

.form {


    &-textbox {
        max-width: calc(100% - 90px);
        width: 100%;
        height: 40px;
        padding: 0;
        background-color: $lightgrey;
        border: #aaa 2px solid;
        font-size: 16px;
        padding-left: 10px;

        &:first-child {
            border-bottom: none;
        }
    }

    &-input {
        width: 100%;
        margin-top: 20px;
        color: #aaa;
    }

    &-alert {
        color: red;
        font-weight: 500;
    }

    &-success {
        color: $color-primary;
        font-weight: 500;
    }
}