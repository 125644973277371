// ==========================================================================
// Global Config
// ==========================================================================

// Font Stacks
$font-family--heading	:	'heineken_core','Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family--content	:	'heineken_core','Helvetica Neue', Helvetica, Arial, sans-serif;

// Max width
$max-width				:	1280px;

// Color Usage
$Cinnabar				: 	#7ab800;
$color-primary			: 	$Cinnabar;

$white    				: 	#FFF;
$black    				: 	#000;

$grey					:	#777;
$grey-96				:	#F5F5F5;
$grey-94				:	#F0F0F0;

$orange                 :   #eda04f;
$bluelight              :   #80bfe4;
$bluenormal             :   #60afdd;
$lightgrey              :   #f5f5f5;

$dark-primary           :   #205527;


// Text
$base__font-size		: 	16;
$base__line-height		: 	24;

$spacing				: 	1.125em;


// Transitions
$trns-duration  		: 	.3s;

// Path to Assets
$path--rel      		: 	"../images";

// Breakpoints
$brkpoint-sm    		: 	36.875em; 	// 590px
$brkpoint-md    		: 	45em; 		// 720px
$brkpoint-lg    		: 	61.25em; 	// 980px
$brkpoint-xl    		: 	80em; 		// 1280px
$brkpoint-xxl    		: 	90em; 		// 1440px