// =========== Card
.card {
	position: relative;
	overflow: hidden;
	cursor: pointer;
	background: #CCC;
	border-bottom: solid 1px rgba(0,0,0,0.1);
	height: 160px;
	background-size:cover;
	background-position: center center;
	background-repeat: no-repeat;

	h2 {
		position: relative;
		color: white;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		line-height: 18px;
		width: calc(100% - 40px);
		max-width: 200px;
		border-top: dashed 3px #fff;
		border-bottom: dashed 3px #fff;
		text-align: center;
		padding: 6px 0 12px;
		height: auto;
	}

	.card-number {
		display: none;
		float: left;
		background-color: $orange;
		width: 40px;
		height: 40px;
		color: white;
		font-weight: 600;
		padding: 9px;
		margin: 10px;
		text-align: center;
	}
}