// =========== Nav
.search-nav {
	@include mq(medium) {
		display: none;
	}

	height: 60px;
	float: left;
	position: relative;
	width: 100%;
	background-color: $dark-primary;
	z-index: 40;
	transition: top 0.2s linear;
	top: 0;

	&.active {
		top: -60px;
	}

	.search-label, input {
		float: left;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}

	.search-label {
		font-weight: 700;
		font-size: 18px;
		margin-left: 20px;
	}

	input[type="submit"] {
		background-color: $orange;
		height: 38px;
		float: right;
		margin-right: 20px;
	}

	#search-number {
		height: 38px;
		width: 50px;
		float: right;
		color: black;
		font-weight: 600;
		font-size: 16px;
		text-align: center;
	}
}

.nav-wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url(../images/menu_bg.jpg);
	background-repeat:no-repeat;
	background-size: cover;
	z-index: 50;

	@include mq(medium) {
		background-image: url(../images/background-menu-bg.png);
	}
}

.nav{
	list-style: none;
	margin: 0;
	padding: 0;
	border-left: 1px solid rgba(255,255,255,0.3);
	position: relative;
	left: 50%;
	top: 0;
	transform: translate(-50%, 0);
	display: inline-block;

	@include mq(medium) {
		border-top: 1px solid rgba(255,255,255,0.3);
		left: 0;
		top: 50%;
		transform: translate(0, -50%);
	}

	> li {
		width: 60px;
		height: 60px;
		float: left;
		border-right: 1px solid rgba(255,255,255,0.3);
		position: relative;

		@include mq(medium) {
			border-bottom: 1px solid rgba(255,255,255,0.3);
		}

        .active {
            background-color: $dark-primary;
        }

		> a {
			color: $white;
			display: block;
			font-size: 9px;
			font-weight: 700;
			text-align: center;
			padding: 21px 0 19px 0;
			&#inhoud_btn{
				padding: 21px 0 13px 0;
			}
			.icon{
				font-size: 16px;
				margin-bottom: 5px;
			}
		}
		&:hover{
			> ul{
				display: block;
			}
		}
	}
}

.secondary__nav{
	list-style: none;
	padding: 0 12px;
	margin: 0;
	width: 100%;
	background: $white;

	li{
		background: $grey-94 url(../images/Background_progress.png) repeat-y right top;
		margin-bottom: 2px;
		position: relative;
		a{
			display: block;
			font-size: 14px;
			padding: 12px 18px;
			line-height: 1.2;
			overflow: visible;
			.progress{
				position: absolute;
			    right: 0;
			    top: 0;
			    height: 100%;
			    width: 4px;
			    background-color: $color-primary;
			}
			&:hover{
				background: $grey-96;
			}
			&.active{
				.progress__wrap span{
					display: block;
				}
			}
		}
	}
}
