// =========== Theme
.l-cards{
	//margin: 0 0 60px 0;
}
.l-left__content{
	background: $white;
	position: relative;
	.secondary__nav__wrap{
		background: $white;
		&.is-sticky{
			.secondary__nav{
				margin-top: 12px;
				padding-top: 12px;
				z-index: 39;
			}
		}
	}
}
.l-right__content{
	h1, h2, h3, h4, h5, h6 {
		color: #205527;
	}

	iframe {
		//width: 100%;
		//height: auto;
		//min-height: 250px;
	}

	padding: 12px;

	h2{
		font-size: 28px;
		line-height: 1.2;
		font-weight: 500;
		margin: 0 0 25px 0;
	}
	p{
		line-height: 1.375;
		font-size: 15px;
		font-weight: 300;
	}
	ul {
		list-style: none;
		padding:0;
		margin:0;
	}

	li {
		padding-left: 1em;
		text-indent: -.7em;
		line-height: 1.375;
		font-size: 15px;
		font-weight: 300;
	}

	li:before {
		content: "• ";
		color: #666;
	}
}

.read-more-wrap {
	position: relative;
	margin-top: -20px;
	background-color: white;
	cursor: pointer;
	display: inline-block;
	padding-top: 10px;

	.label {
		width: 100%;
		text-align: left;
		color: #7ab800;
		font-size: 16px;
		font-weight: 400;
		//text-transform: uppercase;
		text-decoration: underline;
	}
}

div[id*=section] {
	//min-height: 300px;
	overflow: hidden;
	transition: max-height 0.5s ease-in;
}

.readmorepage {
	height: 70% !important;
}

.news-block {
	position: relative;
	float: left;
	display: block;
	width: 100%;
	height: auto;
	border-bottom: solid 1px #CCC;
	padding: 20px 0;
	cursor: pointer;

	@include mq(medium) {
		width: calc(33.3% - 20px);
		margin: 0 20px 10px 0;
		border: none;
		display: inline-block;
	}

	&.first {
		border-top: solid 1px #CCC;

		@include mq(medium) {
			border-top: none;
		}
	}

	.title {
		color: #205527;
		font-size:16px;
		font-weight: 600;
		top: 50%;
		position: absolute;
		transform: translateY(-50%);
		max-width: calc(100% - 130px);
		
		@include mq(medium) {
			position: relative;
			float: left;
			transform: none;
			top: inherit;
			text-align: left;
			width: 100%;
			max-width: none;
		}
	}

	.subtitle {
		display: none;

		@include mq(medium) {

		}
	}

	.news-image {
		float: right;
		width: 100px;
		height: 80px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		@include mq(medium) {
			width: 100%;
			height: 140px;
		}
	}
}

.padding-top {
	padding-top: 30px;
}

img.download-image {
	cursor: pointer;
}

.download-wrap {
	position: relative;
}
.download-wrap .icon {
	cursor: pointer;
	pointer-events: none;
	position: absolute;
	width: 50px;
	height: 50px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-image: url('../images/download-icon.png');
	background-repeat: no-repeat;
	background-position: center center;
}

.select-wrap {
	color: #205527;
	font-size: 20px;

	select {
		margin: 5px 0 20px 0;
		height: 30px;
		width: 150px;
		border: solid 1px #ccc;
		font-size: 16px;
	}
}


.swiper-container {
	//width: 330px;
	//max-width: 100%;
	height: 250px;
	width: 340px;
	font-size: 20px;
	margin-bottom: 50px;
	position: relative;

	@include mq(medium) {
		width: 700px;
	}
}


.button {
	&--swiper {
		position: absolute;
		height: 100%;
		width: 40px;
		background-color: rgba(0,0,0,0.7);
		opacity: .8;
		z-index: 2;
		cursor: pointer;
		background-position: center center;
		background-repeat: no-repeat;
		transition: opacity 0.2s ease-out;
		//border-radius: 6px;

		&--next {
			top: 0;
			right: 0;
			background-image: url('../images/slider-next.png');
		}

		&--prev {
			top: 0;
			left: 0px;
			background-image: url('../images/slider-prev.png');
		}

		&.inactive {
			display: none;
		}

		&:hover {
			opacity: .5;
		}
	}
}

.translation-other {
}
.translation-english {
	font-weight: 700;
}
.translation-fonetisch {
	font-style: italic;
	font-weight: 500;
	text-align: center;
}

.translation-wrap {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	line-height: 16px;

	div {
		margin-bottom: 10px;
		text-align: center;
		padding: 0 50px;
	}
}