// ===========  Icon
[data-icon]:before, .icon, #downloadBtn:after {
    font-family: 'icon_font';
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: auto;
    text-align: center;
    line-height: 1;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;

	content: attr(data-icon);

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* IE 6+ Support ========== */
	/*	behavior: url(ie-css3.htc); */

}

.icon{
	color: $white;
	display: block;
	&--primary{
		color: $color-primary;
	}
}

.icon-search {
    height: 15px;
    width: 16px;
    background-image: url('../images/search_icon.svg');
    background-repeat: no-repeat;
    position: relative;
    margin: 1px 0 0 20px;
}

.icon-menu {
    height: 15px;
    width: 15px;
    background-image: url('../images/menu_icon.png');
    background-size: contain;
    position: relative;
    margin: 1px 0 0 22px;
}

.icon-1:before {
    //content: "\e60c";
    content: "\e613";
}
.icon-2:before {
    content: "\e608";
}
.icon-3:before {
    content: "\e607";
}
.icon-4:before {
    content: "\e60a";
}
.icon-5:before {
    content: "\e612";
}
.icon-6:before {
    content: "\e60f";
}
.icon-7:before {
    content: "\e60b";
}
.icon-8:before {
    content: "\e609";
}
.icon-9:before {
    content: "\e611";
}
.icon-10:before {
    content: "\e60d";
}
.icon-11:before {
    content: "\e60e";
}
.icon-12:before {
    content: "\e610";
}
.icon-0:before {
    content: "\e606";
}
.icon-chevron_left:before {
    content: "\e603";
}
.icon-chevron_right:before {
    content: "\e604";
}

.button__readmore:before{
	width: 50px;
	line-height: 48px;
}

#downloadBtn:after{
	content: "\e601";
	font-size: 10px;
	color: $color-primary;
	margin-left: 4px;
}