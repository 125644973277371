// ==========================================================================
// Helpers
// ==========================================================================

// Clearfix

.clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

.l-clear{
	clear: both;
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
//
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
//
// Credit: HTML5 Boilerplate

.sr-only-focusable {
	&:active,
	&:focus {
		position: static;
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
	}
}

// Alignment

.text-left {
	text-align: left;
	}
.text-right{
	text-align: right;
}
.text-center{
	text-align: center;
}

// Center elements

.centered {
	float: none;
	margin-left: auto;
	margin-right: auto;
}

// Other

.hide{
	display: none;
}

.text-hide {
	font: "0/0" a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.brand__grandient{
	background: #ef8647;
	background: -moz-linear-gradient(45deg,  #ef8647 0%, #e52939 100%);
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,#ef8647), color-stop(100%,#e52939));
	background: -webkit-linear-gradient(45deg,  #ef8647 0%,#e52939 100%);
	background: -o-linear-gradient(45deg,  #ef8647 0%,#e52939 100%);
	background: -ms-linear-gradient(45deg,  #ef8647 0%,#e52939 100%);
	background: linear-gradient(45deg,  #ef8647 0%,#e52939 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef8647', endColorstr='#e52939',GradientType=1 );
	color: $white;
}

.light {
	font-weight: 300;
}

.semibold {
	font-weight: 600;
}

.bold {
	font-weight: 700;
}

.bg-orange {
	background-color: $orange;
}

.bg-blue {
	background-color: $bluenormal;
}

.bg-lightblue {
	background-color: $dark-primary;
}