.background {
    background: url('../images/login-bg.jpg');
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
}

.login-popup {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: auto;
    //display: inline-block;
    //box-shadow: 0 0 5px 2px rgba(255,255,255, 1);

    &__header {
        background: #ccc;
        height: 50px;
        text-align: center;

        .title {
            padding: 14px;
        }
    }

    .form-row {
        position: relative;
        float: left;
        width: 100%;
        height: auto;
        background: #ccc;


        input {
            height: 50px;
            width: 100%;
            padding-left: 10px;
        }

        .error-row {
            color: #9A0000;
            padding: 4px 0 0 10px;
        }
    }

    .submit-row {
        position: relative;
        float: left;
        background: #ccc;
        width: 100%;

        .submit {
            margin: 10px;
            padding: 10px;
            border-radius: 4px;
            background-color: #7AB800;
            text-align: center;
            cursor: pointer;
            border: none;
            width: calc(100% - 20px);
        }
    }
}

form {
    padding: 0;
    margin: 0;
}