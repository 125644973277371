// =========== Sidebar
.l-sidebar{
	position: fixed;
	z-index: 49;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 60px;
	background: $color-primary;
	color: $white;


	@include mq(medium) {
		width: 60px;
		height: 100%;
	}
}
