@font-face {
    font-family: 'icon_font';
    src: url('../fonts/icomoon.eot');
    src: url('../fonts/icomoon.eot?#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.woff') format('woff'),
    url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Generated by Font Squirrel (https://www.fontsquirrel.com) on July 12, 2016 */



@font-face {
    font-family: 'heineken_core';
    src: url('../fonts/heineken_core-bold-webfont.woff2') format('woff2'),
    url('../fonts/heineken_core-bold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}




@font-face {
    font-family: 'heineken_core';
    src: url('../fonts/heineken_core-bolditalic-webfont.woff2') format('woff2'),
    url('../fonts/heineken_core-bolditalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;

}




@font-face {
    font-family: 'heineken_core';
    src: url('../fonts/heineken_core-extrabold-webfont.woff2') format('woff2'),
    url('../fonts/heineken_core-extrabold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}




@font-face {
    font-family: 'heineken_core';
    src: url('../fonts/heineken_core-italic-webfont.woff2') format('woff2'),
    url('../fonts/heineken_core-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'heineken_core';
    src: url('../fonts/heineken_core-light-webfont.woff2') format('woff2'),
    url('../fonts/heineken_core-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'heineken_core';
    src: url('../fonts/heineken_core-lightitalic-webfont.woff2') format('woff2'),
    url('../fonts/heineken_core-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;

}




@font-face {
    font-family: 'heineken_core';
    src: url('../fonts/heineken_core-thin-webfont.woff2') format('woff2'),
    url('../fonts/heineken_core-thin-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;

}




@font-face {
    font-family: 'heineken_core';
    src: url('../fonts/heineken_core-thinitalic-webfont.woff2') format('woff2'),
    url('../fonts/heineken_core-thinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'heineken_core';
    src: url('../fonts/heineken_core-webfont.woff2') format('woff2'),
    url('../fonts/heineken_core-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}