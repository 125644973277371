/*
 * Swiper 2.7.6
 * Mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/sliders/swiper/
 *
 * Copyright 2010-2015, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under GPL & MIT
 *
 * Released on: February 11, 2015
*/
/* ===============================================================
Basic Swiper Styles
================================================================*/
.swiper-container {
    margin:0 auto;
    position:relative;
    overflow:hidden;
    direction:ltr;
    -webkit-backface-visibility:hidden;
    -moz-backface-visibility:hidden;
    -ms-backface-visibility:hidden;
    -o-backface-visibility:hidden;
    backface-visibility:hidden;
    /* Fix of Webkit flickering */
    z-index:1;
}
.swiper-wrapper {
    position:relative;
    width:100%;
    -webkit-transition-property:-webkit-transform, left, top;
    -webkit-transition-duration:0s;
    -webkit-transform:translate3d(0px,0,0);
    -webkit-transition-timing-function:ease;

    -moz-transition-property:-moz-transform, left, top;
    -moz-transition-duration:0s;
    -moz-transform:translate3d(0px,0,0);
    -moz-transition-timing-function:ease;

    -o-transition-property:-o-transform, left, top;
    -o-transition-duration:0s;
    -o-transform:translate3d(0px,0,0);
    -o-transition-timing-function:ease;
    -o-transform:translate(0px,0px);

    -ms-transition-property:-ms-transform, left, top;
    -ms-transition-duration:0s;
    -ms-transform:translate3d(0px,0,0);
    -ms-transition-timing-function:ease;

    transition-property:transform, left, top;
    transition-duration:0s;
    transform:translate3d(0px,0,0);
    transition-timing-function:ease;

    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.swiper-free-mode > .swiper-wrapper {
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -ms-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    margin: 0 auto;
}
.swiper-slide {
    float: left;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
    -ms-touch-action: pan-y;
}
.swiper-wp8-vertical {
    -ms-touch-action: pan-x;
}

/* ===============================================================
Your custom styles, here you need to specify container's and slide's
sizes, pagination, etc.
================================================================*/
.swiper-container {
    /* Specify Swiper's Size: */

    /*width:200px;
    height: 100px;*/
}
.swiper-slide {
    /* Specify Slides's Size: */

    /*width: 100%;
    height: 100%;*/
}
.swiper-slide-active {
    /* Specific active slide styling: */

}
.swiper-slide-visible {
    /* Specific visible slide styling: */

}
/* ===============================================================
Pagination Styles
================================================================*/
.swiper-pagination-switch {
    /* Stylize pagination button: */

}
.swiper-active-switch {
    /* Specific active button style: */

}
.swiper-visible-switch {
    /* Specific visible button style: */

}