.l-container{
	//position: relative;
	float: left;
	margin: 0 auto;
	padding: 0 0 48px 0;
	width: 100%;
}
.l-content{
	padding: 20px 0;
}
.content__wrap{
	position: relative;
	min-height: 100vh;
	background-color: white;
	overflow: hidden;
	padding-bottom: 40px;
}

@include mq(medium) {
	.l-container{
		padding: 0 60px 0 0;
	}
	.l-left__content{
		width: 30%;
		float: left;
	}
	.l-right__content{
		width: 70%;
		float: right;
	}
}